import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./LoginForm.css";
import { v4 as uuidv4 } from "uuid";

const LoginForm = () => {
  const { handleSubmit } = useForm();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function getCookieValue(name) {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }

  async function getFingerprint() {
    try {
      const response = await fetch(
        `https://api.ipstack.com/check?access_key=b11d9c368a596ea01db0a2565016acfa`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error fetching fingerprint data:", error);
      return null;
    }
  }

  async function sendDataToCreateUserAPI(data) {
    try {
      const response = await fetch(
        "https://asia-northeast1-user-journey-mapping-stag.cloudfunctions.net/user/createSiteCookie",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        localStorage.setItem("setCookies", "true");
        return {
          csuid: jsonResponse.result.csuid,
          cscid: jsonResponse.result.cscid,
        };
      } else {
        console.log("Failed to send data");
      }
    } catch (error) {
      console.log("Error sending data to API:", error);
    }
  }

  async function sendDataToUpdateFingerprintAPI(data) {
    try {
      await fetch(
        `https://asia-northeast1-user-journey-mapping-stag.cloudfunctions.net/user/updateSiteCookie?csuid=${data.credentials[0].csuid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    } catch (error) {
      console.log("Error sending data to API:", error);
    }
  }

  function getBrowser(userAgent) {
    if (userAgent.includes("Chrome")) {
      const regex = /Chrome\/([^\s]*)/;
      const version = userAgent.match(regex)?.[1] || "";
      return `Chrome ${version}`;
    } else if (userAgent.includes("Firefox")) {
      const regex = /Firefox\/([^\s]*)/;
      const version = userAgent.match(regex)?.[1] || "";
      return `Firefox ${version}`;
    } else if (userAgent.includes("Safari")) {
      const regex = /Version\/([^\s]*)/;
      const version = userAgent.match(regex)?.[1] || "";
      return `Safari ${version}`;
    } else if (userAgent.includes("Edge")) {
      const regex = /Edge\/([^\s]*)/;
      const version = userAgent.match(regex)?.[1] || "";
      return `Edge ${version}`;
    } else if (userAgent.includes("Opera")) {
      const regex = /Opera\/([^\s]*)/;
      const version = userAgent.match(regex)?.[1] || "";
      return `Opera ${version}`;
    } else {
      return "";
    }
  }

  function getMobileOSVersion(userAgent) {
    if (/iPhone/.test(userAgent)) {
      const regex = /OS (\d+)_(\d+)_?(\d+)?/;
      const version = "I" + userAgent.match(regex)?.[0] || "";
      return version;
    } else if (/Android/.test(userAgent)) {
      const regex = /Android (\d+)\.(\d+)\.?(\d+)?/;
      const version = userAgent.match(regex)?.[0] || "";
      return version;
    } else if (/iPad/.test(userAgent)) {
      const regex = /iPad\s*([\d._]*)/;
      const version = userAgent.match(regex)?.[0] || "";
      return version;
    } else {
      return "";
    }
  }

  useEffect(async () => {
    let csuid = getCookieValue("csuid");
    let cscid = getCookieValue("cscid");
    const screenResolution = `${window.screen.width} *
        ${window.screen.height}`;
    const browserPlugins = Array.from(navigator.plugins).map(
      (plugin) => plugin.name
    );

    const userAgent = navigator.userAgent;
    const phoneModel = navigator.userAgent;
    const localTime = new Date().toLocaleString();

    const fingerprint = await getFingerprint();
    const operatingSystem = getMobileOSVersion(userAgent);
    const browser = getBrowser(userAgent);

    if (fingerprint != null) {
      const ipAddress = fingerprint.ip != null ? fingerprint.ip : "";
      const city = fingerprint.city != null ? fingerprint.city : "";
      const region =
        fingerprint.region_name != null ? fingerprint.region_name : "";
      const country =
        fingerprint.country_name != null ? fingerprint.country_name : "";
      const latitude = fingerprint.latitude != null ? fingerprint.latitude : "";
      const longitude =
        fingerprint.longitude != null ? fingerprint.longitude : "";
      const zipcode = fingerprint.zip != null ? fingerprint.zip : "";
      const currency =
        fingerprint.currency != null ? fingerprint.currency.code : "";
      const isp =
        fingerprint.connection != null ? fingerprint.connection.isp : "";
      const preferenceLanguage =
        fingerprint.location != null
          ? fingerprint.location.languages[0].code
          : "";
      const timestamp = Date.now();

      const data = {
        credentials: [
          {
            preference_language: preferenceLanguage,
            timestamp: timestamp,
          },
        ],
        ip_address: ipAddress,
        screen_resolution: screenResolution,
        browser_plugins: browserPlugins,
        operating_system: operatingSystem,
        user_agent: userAgent,
        phone_model: phoneModel,
        local_time: localTime,
        city: city,
        region: region,
        country: country,
        latitude: latitude,
        longitude: longitude,
        currency: currency,
        isp: isp,
        browser: browser,
        zip_code: zipcode,
      };

      if (cscid && csuid) {
        data.credentials[0].cscid = cscid;
        data.credentials[0].csuid = csuid;
        await sendDataToUpdateFingerprintAPI(data);
      } else {
        const { cscid: newCscid, csuid: newCsuid } =
          await sendDataToCreateUserAPI(data);
        cscid = newCscid;
        csuid = newCsuid;
      }

      const cookieString =
        `csuid=${csuid}; ` +
        `cscid=${cscid}; ` +
        `preference_language=${preferenceLanguage}; ` +
        `timestamp=${timestamp}; ` +
        `ip_address=${ipAddress}; ` +
        `screen_resolution=${JSON.stringify(screenResolution)}; ` +
        `browser_plugins=${JSON.stringify(browserPlugins)}; ` +
        `operating_system=${operatingSystem}; ` +
        `user_agent=${userAgent}; ` +
        `phone_model=${phoneModel}; ` +
        `local_time=${localTime}; ` +
        `city=${city}; ` +
        `region=${region}; ` +
        `country=${country}; ` +
        `latitude=${latitude}; ` +
        `longitude=${longitude}; ` +
        `currency=${currency}; ` +
        `zipcode=${zipcode}; ` +
        `browser=${browser}; ` +
        `isp=${isp}`;

      const cookieArray = cookieString.split("; ");

      cookieArray.forEach((cookie) => {
        document.cookie = `${cookie}; Secure; SameSite=None`;
      });
    }
  }, []);

  async function onSubmit() {}

  return (
    <div className="login-form">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <label htmlFor="inputEmail">E-mail</label>
        <input
          type="email"
          id="inputEmail"
          name="email"
          onChange={(e) => setUsername(e.target.value)}
        />

        <label htmlFor="inputPassword">Password</label>
        <input
          type="password"
          id="inputPassword"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginForm;
